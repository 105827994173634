<template>
  <div class="app-container">
    <CrudTable ref="table" entity="LoanStatusChangeTrigger" :columns="columns" />
  </div>
</template>

<script>
import crudIndexMixin from '@/mixins/crud-index';
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  data() {
    return {
      columns: [
        { field: 'id', width: 60, label: 'ID' },
        { field: 'status', label: 'Status', formatter: row => row.status.name },
        {
          field: 'onAutomaticChange',
          label: 'LoanStatusChangeTrigger.onAutomaticChange',
          formatter: (row, column, value) => {
            if (value === true) {
              return this.$t('LoanStatusChangeTrigger.BySystemChange');
            } else if (value === false) {
              return this.$t('LoanStatusChangeTrigger.ByManualChange');
            } else if (value === null) {
              return this.$t('LoanStatusChangeTrigger.Always');
            }
          }
        },
        {
          field: 'whenLoanUnderAgreementLimit',
          label: 'LoanStatusChangeTrigger.whenUnderLoanAgreementLimit',
          formatter: (row, column, value) => {
            if (value === true) {
              return this.$t('LoanStatusChangeTrigger.UnderAgreementLimit');
            } else if (value === false) {
              return this.$t('LoanStatusChangeTrigger.AboveAgreementLimit');
            } else if (value === null) {
              return this.$t('LoanStatusChangeTrigger.Always');
            }
            return value;
          }
        },
        {
          field: 'loanTypeId',
          label: 'LoanStatusChangeTrigger.loanType',
          formatter: (row, column, value) => {
            if (value === null) {
              return this.$t('LoanStatusChangeTrigger.all');
            }
            return row.loanType.name;
          }
        },
        { field: 'description', label: 'common.description' }
      ]
    };
  }
};
</script>

<style scoped></style>
